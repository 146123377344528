




















































import { updateLocationData } from "@/api/location";
import { rootInstance } from "@/helpers/composition";
import { IOrganizationState } from "@/lib/types/organization";
import { formatDate, timeAgo } from "@/plugins/dayjs";
import { defineComponent, inject, reactive, Ref, toRefs } from "@vue/composition-api";

import { Notification, Message } from "element-ui";

export default defineComponent({
  name: "OrganizationSettings",
  components: {
    OrganizationDetails: () => import("@/components/organization/OrganizationDetails.vue"),
    LocationForm: () => import("@/components/forms/LocationForm.vue"),
    RetailerCrudForm: () => import("@/components/forms/RetailerCrudForm.vue"),
    ConsumerCrudForm: () => import("@/components/forms/ConsumerCrudForm.vue"),
    ProducerCrudForm: () => import("@/components/forms/ProducerCrudForm.vue"),
  },
  setup () {
    const { root } = rootInstance();

    const organization: Ref<IOrganizationState> | undefined = inject("organization");

    const state = reactive({
      ukey: 0,
      editOrganizationModal: false,
      editLocationModal: false,
      locationForm: organization ? { ...organization.value.details.location } : {},
      isRouterAlive: true,
    });

    const organizationUpdated = () => {
      root.$store.dispatch("user/setUserDetails");
      state.ukey++;
      state.editOrganizationModal = false;
      state.editLocationModal = false;
    };

    const submitLocation = () => {
      if (organization) {
        updateLocationData(organization.value.details.location.id, { ...state.locationForm })
          .then(() => {
            organizationUpdated();

            Notification({
              title: "Operation successful",
              message: "Location updated successfully",
              type: "success",
              duration: 5000,
              showClose: true,
              position: "bottom-left",
            });
          })
          .catch(err => {
            Message({
              message: `${err}`,
              type: "warning",
              duration: 3000,
              showClose: true,
            });
          });
      }
    };
    const closeDialog = () => {
      root.$confirm(
        /* Message */
        root.$i18n.t("messages.sure_to_close") as string,
        /* Title */
        root.$i18n.t("labels.warning") as string,
        {
          confirmButtonText: "OK",
          cancelButtonText: root.$i18n.t("labels.cancel") as string,
          type: "warning",
          center: true,
          showClose: false,
          dangerouslyUseHTMLString: true,

          beforeClose: async (action, instance, done) => {
            if (action === "confirm") {
              instance.confirmButtonLoading = true;
              state.isRouterAlive = false;
              root.$nextTick(() => {
                state.isRouterAlive = true;
              });
              state.editOrganizationModal = false;
              instance.confirmButtonLoading = false;
              done();
              /*  */
            } else {
              done();
            }
          },
        });
    };

    return { ...toRefs(state), formatDate, timeAgo, organization, organizationUpdated, submitLocation, closeDialog };
  },
});
