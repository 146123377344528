// eslint-disable-next-line
// @ts-ignore
import { locationsApi } from "../plugins/greenlink.js";
import { ILocation } from "@/lib/types/location";

/**
 * ! LOCATION DATA
 */
export function createLocationData (payload: Partial<ILocation>) {
  return new Promise<ILocation>((resolve, reject) => {
    locationsApi.locationsCreate(payload, (error: any, _data: any, response: any) => {
      if (error) {
        reject(error);
      } else {
        resolve(response.body);
      }
    });
  });
}

export function updateLocationData (id: string, payload: Partial<ILocation>) {
  return new Promise<ILocation>((resolve, reject) => {
    const opts = {
      patchedLocation: payload,
    };

    locationsApi.locationsPartialUpdate(id, opts, (error: any, _data: any, response: any) => {
      if (error) {
        reject(error);
      } else {
        resolve(response.body);
      }
    });
  });
}
